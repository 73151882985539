import request from '@/util/request'

export const qrcodeUnion = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/union',
    'method': 'GET',
    params
  })
}

export const qrCodeList = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/list',
    'method': 'GET',
    params
  })
}

export const qrCodeCreate = (data) => {
  return request({
    'url': '/api/v1/marketing/qrcode/create',
    'method': 'POST',
    data
  })
}
export const qrCodeEdit = (data) => {
  return request({
    'url': '/api/v1/marketing/qrcode/edit',
    'method': 'POST',
    data
  })
}

export const qrCodeDetail = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/detail',
    'method': 'GET',
    params
  })
}

export const qrCodeDelete = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/delete',
    'method': 'GET',
    params
  })
}

export const qrcodeItemUnion = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/item/union',
    'method': 'GET',
    params
  })
}

export const qrCodeItemList = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/item/list',
    'method': 'GET',
    params
  })
}

export const qrcodeItemCnt = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/item/cnt',
    'method': 'GET',
    params
  })
}

export const qrCodeItemFreeze = (params) => {
  return request({
    'url': '/api/v1/marketing/qrcode/item/freeze',
    'method': 'GET',
    params
  })
}
